<template>
  <div class="container">
    <div class="header-nav">
      <span class="hn-one" @click="returnFor">灵活用工</span>
      <a-icon class="hn-two" type="right" />
      <span>{{raskDetailInfo.title}}</span>
    </div>
    <div class="rask-content" v-if="raskDetailInfo">
      <a-row :gutter="24">
        <a-col :span="8" class="rc-mgb">报名时间：{{raskDetailInfo.enrollBeginDate.split(' ')[0]}} 至 {{raskDetailInfo.enrollEndDate.split(' ')[0]}}</a-col>
        <a-col :span="8" class="rc-mgb">任务时间：{{raskDetailInfo.workBeginDate.split(' ')[0]}} 至 {{raskDetailInfo.workEndDate.split(' ')[0]}}</a-col>
        <a-col :span="8" class="rc-mgb">预计人数：{{raskDetailInfo.needNum}}</a-col>
        <a-col :span="8" class="rc-mgb">预算金额：{{raskDetailInfo.budgetAmount===null?'--':raskDetailInfo.budgetAmount.toFixed(2)}}</a-col>
        <a-col :span="8" class="rc-mgb">
          任务状态：
          <span v-if="raskDetailInfo.status==='10'">待审核</span>
          <span v-if="raskDetailInfo.status==='11'">审核失败</span>
          <span v-if="raskDetailInfo.status==='13'">报名中</span>
          <span v-else-if="raskDetailInfo.status==='14'">报名截止</span>
          <span v-else-if="raskDetailInfo.status==='20'">进行中</span>
          <span v-else-if="raskDetailInfo.status==='30'">已完成</span>
          <span v-else-if="raskDetailInfo.status==='50'">已取消</span>
        </a-col>
        <a-col :span="8" class="rc-mgb">结算类型：{{raskDetailInfo.payMode==='10'?'单次结算':'多次结算'}}</a-col>
        <a-col :span="8" class="rc-mgb">任务描述：{{raskDetailInfo.content}}</a-col>
      </a-row>
    </div>
    <div class="search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="业务员名称" :labelCol="{lg: {span: 8}, sm: {span: 7}}" :wrapperCol="{lg: {span: 16}, sm: {span: 17} }">
              <a-input v-model="queryParam.relationName" placeholder="请输入业务员名称"/>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="门店名称" :labelCol="{lg: {span: 8}, sm: {span: 7}}" :wrapperCol="{lg: {span: 16}, sm: {span: 17} }">
              <a-input v-model="queryParam.fullName" placeholder="请输入门店名称"/>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchEnterprise">重置</a-button>
              <a-button type="primary" style="margin-left: 8px" @click="binding" v-if="raskDetailInfo.status!=='30'">关联</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="table"
        :columns="raskColumns"
        :data="raskData"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="del(record)" v-if="record.status==='10'">删除</a>
        </span>
      </s-table>
    </div>
    <!-- 添加 -->
    <a-modal
      title="灵活用工"
      :visible="visibleAdd"
      :confirm-loading="confirmLoadingAdd"
      @ok="handleOkAdd"
      @cancel="handleCancelAdd"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleOkAdd">
        <a-form-item label="服务日期">
          <a-range-picker
            placeholder="服务日期"
            v-decorator="['date', { rules: [{ required: true, message: '请选择服务日期!' }] }]"
          />
        </a-form-item>
        <a-form-item label="管理用户">
          <a-select style="width: 100%" placeholder="请选择管理用户" showSearch :defaultActiveFirstOption="false" :filterOption="false" @search="handleSearchSelectCompany" @change="handleChangeSelectCompany" v-decorator="['relationId', {rules:[{ required: true, message: '请选择管理用户' }]}]">
            <a-select-option v-for="item in scopeRateList" :key="item.userId" :value="item.userId">{{item.userName}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="服务门店">
          <a-select style="width: 100%" placeholder="请选择服务门店" showSearch :defaultActiveFirstOption="false" :filterOption="false" @search="handleSearchSelectCompanyRask" @change="handleChangeSelectCompanyRask" v-decorator="['merchantIds', {rules:[{ required: true, message: '请选择服务门店', }]}]">
            <a-select-option v-for="item in storeList" :key="item.id" :value="item.id">{{item.fullName}}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="服务人员" v-if="servePersons.length>0">
          <div class="serveStore">
            <span v-for="(item,index) in servePersons" :key="index">{{item.relationWorkerUserName}} {{item.serviceBeginDate}}~{{item.serviceEndDate}}<br v-if="index<servePersons.length-1"></span>
          </div>
        </a-form-item>
        <a-form-item label="提成比例">
          <a-input
            placeholder="请填写提成比例"
            type="number"
            suffix="%"
            v-decorator="['rebatePoint', { rules: [{ required: true, message: '请填写提成比例!' }] }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/';
import moment from 'moment'
import {
  enterpriseMerchantList,
  merchantServiceConfigList,
  taskWorker,
  merchantServiceConfig,
  getServiceMerchantBox,
  delServiceUser,
} from '@/api/api';
export default {
  components: {
    STable
  },
  data () {
    return {
      raskColumns: [
        {
          title: '业务员',
          dataIndex: 'relationWorkerUserName',
        },
        {
          title: '服务门店',
          dataIndex: 'fullName',
        },
        {
          title: '提成比例(%)',
          dataIndex: 'rebatePoint',
          customRender: text => text===null?'--':(text*100)
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: text => text=== '10' ? '未开始' : text === '20' ? '服务中' : text=== '30' ? '服务完成':'服务失败'
        },
        {
          title: '服务开始日期',
          dataIndex: 'serviceBeginDate',
          customRender: text => text===null?'--':text
        },
        {
          title: '服务结束日期',
          dataIndex: 'serviceEndDate',
          customRender: text => text===null?'--':text
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          align: 'center'
        }
      ],
      visibleAdd: false,
      confirmLoadingAdd:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      queryParam: {
        relationName: '',
        fullName: ''
      },
      date: [],
      scopeRateList: [],
      storeList: [],
      record: '',
      relationWorkerId: '',
      raskDetailInfo: null,
      servePersons: []
    }
  },
  created () {
    this.raskDetailInfo = JSON.parse(window.sessionStorage.getItem('record'))
    console.log(this.raskDetailInfo)
  },
  methods: {
    // 返回
    returnFor () {
      window.localStorage.setItem('submenu','flexibleManage')
      window.sessionStorage.removeItem('record')
      this.$router.push('/flexibleManage')
    },
    // 删除
    del (record) {
      let that = this
      this.$confirm({
        title: '确定删除？',
        onOk() {
          return new Promise((resolve, reject) => {
            delServiceUser({id:record.id}).then((res) => {
              if (res.success) {
                that.$message.success(res.message);
                that.handleToSearchEnterprise();
                resolve();
              } else {
                that.$message.error(res.message);
                reject();
              }
            });
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {
          // console.log('Cancel');
        },
      });
    },
    // 员工
    handleChangeSelectCompany (value) {
      this.scopeRateList.forEach(item=>{
        if (value===item.userId) {
          this.relationWorkerId = item.id
        }
      })
    },
    handleSearchSelectCompany (value) {
      
    },
    // 门店
    handleChangeSelectCompanyRask (value) {
      merchantServiceConfigList({merchantId:value,pageSize: 1000}).then(res => {
        console.log(res)
        if (res.success) {
          this.servePersons = res.result.data
        }
      })
    },
    handleSearchSelectCompanyRask (value) {
      getServiceMerchantBox().then(res =>{
        if (res.success) {
          this.storeList = res.result
        }
      })
    },
    // binding
    binding () {
      // this.record = record
      taskWorker({taskId:this.raskDetailInfo.id,signStatus:'30'}).then(res => {
        if (res.success) {
          this.scopeRateList = res.result.data
        }
      })
      getServiceMerchantBox().then(res =>{
        if (res.success) {
          this.storeList = res.result
        }
      })
      this.visibleAdd = true
    },
    // 选择时间
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.finishTime = dateString[1]
    },
    // 获取列表
    raskData (parameter) {
      return merchantServiceConfigList(Object.assign(parameter,this.queryParam,{taskId:this.raskDetailInfo.id})).then(res => {
        return res.result
      })
    },
    // 查询    
    handleToSearchEnterprise() {
      this.$refs.table.refresh(true)
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        relationName: '',
        fullName: ''
      };
      this.handleToSearchEnterprise();
    },
    // 添加
    handleOkAdd (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoadingAdd = true
          let params = {
            merchantIds: [values.merchantIds],
            rebatePoint:(values.rebatePoint/100).toFixed(2),
            serviceBeginDate:moment(values.date[0]).format('YYYY-MM-DD'),
            serviceEndDate:moment(values.date[1]).format('YYYY-MM-DD'),
            relationNo: this.raskDetailInfo.id,
            relationNoTitle: this.raskDetailInfo.title,
            relationName: '',
            relationId:values.relationId,
            relationWorkerId: this.relationWorkerId
          }
          this.scopeRateList.forEach(item=>{
            if (item.userId===values.relationId) {
              params.relationName=item.userName
            }
          })
          merchantServiceConfig(params).then(res=> {
            this.confirmLoadingAdd = false
            if (res.success) {
              this.$message.success(res.message)
              this.handleToSearchEnterprise()
              this.handleCancelAdd()
            } else {
              this.$message.error(res.message)
            }
          }).catch(()=>{this.confirmLoadingAdd = false})
        }
      });
    },
    handleCancelAdd () {
      this.form.resetFields()
      this.scopeRateList = []
      this.storeList = []
      this.servePersons = []
      this.visibleAdd = false
    },
  }
}
</script>
<style scoped lang="less">
.container{
  background: #FFFFFF;
  border-radius: 4px;
  min-height: 935px;
  padding: 40px;
}
.header-nav{
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 14px;
  padding: 0 0 17px 0;
  border-bottom: 1px solid #DEDEDE;
  .hn-one{
    color: #000000;
    cursor: pointer;
  }
  .hn-two{
    margin: 0 15px;
    color: #DEDEDE;
    font-size: 18px;
  }
}
.rask-content{
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  .rc-mgb{
    margin-top: 24px;
    white-space: nowrap;
    &:last-child{
      width: 100%;
      text-align: justify;
      white-space: normal;
    }
    span{
      color:#1890FF;
    }
  }
}
.header{
  .line{
    width: 2px;
    height: 18px;
    background: #347CF5;
    margin-right: 9px;
  }
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.nav{
  margin: 10px 0 0 0;
  .common{
    color: #999999;
    margin: 0;
  }
}
.opt{
  margin: 20px 0 30px 0;
}
.search-wrapper{
  margin: 30px 0;
}
.serveStore{
  margin: 0px 0 -14px 0px;
}
</style>